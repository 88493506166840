import { IssueDTO } from "./models/Issue";
import IUserInfoData from "./types/userinfo";
import { IssueMessageDTO } from "./models/IssueMessageDTO";
import { IssueFilterDTO } from "./models/IssueFilterDTO";
import { Constants } from "./constants";

// const baseUrl = "http://fleetweave.com:8180";
const url =
    Constants.URL_ISSUES_SERVICE + Constants.URL_ISSUE_UNCLOSED_ENDPOINT;
const updateurl =
    Constants.URL_ISSUES_SERVICE + Constants.URL_ISSUE_CLOSED_ENDPOINT;
const updateissuestatusurl =
    Constants.URL_ISSUES_SERVICE + Constants.URL_ISSUE_UPDATE_STATUS_ENDPOINT;
const msgurl =
    Constants.URL_ISSUES_SERVICE + Constants.URL_ISSUEFILTERED_CLOSED_ENDPOINT;
const filteredurl =
    Constants.URL_ISSUES_SERVICE +
    Constants.URL_ISSUEFILTERED_UNCLOSED_ENDPOINT;
const imageurl =
    Constants.URL_ISSUES_SERVICE + Constants.URL_ISSUEIMAGE_CLOSED_ENDPOINT;
function parseJSON(response: Response) {
    console.log("parseJSON");
    return response.json();
}
function getCurrentUser() {
    const data = sessionStorage.getItem("userinfo");
    const userinfodata = JSON.parse(data || "");
    return userinfodata as IUserInfoData;
}
function getBearerToken() {
    const data = sessionStorage.getItem("token");
    const userToken = JSON.parse(data || "");
    return userToken.access_token as string;
}

const IssueAPI = {
    getIssueList() {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        console.log(token);
        return fetch(url, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    getFilteredIssueList(data: IssueFilterDTO) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");

        const message = JSON.stringify({
            issueStatus: data.statusSelected,
            issuePriority: data.prioritySelected,
            pageIndex: data.pageIndex,
            pageSize: data.pageSize,
            sortBy: data.sortBySelected,
        });

        return fetch(filteredurl, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    getFilteredIssues(statusId: string, priorityId: string, page: number) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        console.log(token);

        const completeURL = `${filteredurl}?&status=${statusId}&priority=${priorityId}&page=${page}`;

        return fetch(completeURL, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    getMessageList(issueId: string) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        console.log(token);
        return fetch(msgurl + issueId, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    addMessage(data: IssueMessageDTO) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        console.log("*");

        const user = getCurrentUser();
        const message = JSON.stringify({
            issueId: data.issueId,
            message: data.message,
            senderId: user.sub,
            senderUserName: user.preferred_username,
        });

        return fetch(msgurl, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    getImagesist(issueId: string) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        console.log(token);
        return fetch(imageurl + issueId, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    addIssue(data: IssueDTO) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        console.log("*");
        console.log(data.createEntryDate);
        console.log(data.createEntryTime);
        console.log(data.createDueDate);
        console.log(data.createDueTime);
        console.log("**");
        const starttime = `${String(data.createEntryDate)} ${String(
            data.createEntryTime
        )}`;
        const endtime = `${String(data.createDueDate)} ${String(
            data.createDueTime
        )}`;

        const user = getCurrentUser();
        const message = JSON.stringify({
            assetId: data.createAddAssetId,
            issueStatus: Number(data.createAddIssueStatus),
            issuePriority: Number(data.createAddIssuePriority),
            entryDateTime: Date.parse(starttime),
            dueDateTime: Date.parse(endtime),
            summary: data.createSummary,
            info: data.createInfo,
            label: data.createLabel,
            reportedBy: user.sub,
            assignedTo: data.createAssignedTo,
        });

        return fetch(url, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    updateIssue(data: IssueDTO, issueid: number) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        console.log("*");
        console.log(data.createEntryDate);
        console.log(data.createEntryTime);
        console.log(data.createDueDate);
        console.log(data.createDueTime);
        console.log("**");
        const starttime = `${String(data.createEntryDate)} ${String(
            data.createEntryTime
        )}`;
        const endtime = `${String(data.createDueDate)} ${String(
            data.createDueTime
        )}`;

        const user = getCurrentUser();
        const message = JSON.stringify({
            assetId: data.createAddAssetId,
            issueStatus: Number(data.createAddIssueStatus),
            issuePriority: Number(data.createAddIssuePriority),
            entryDateTime: Date.parse(starttime),
            dueDateTime: Date.parse(endtime),
            summary: data.createSummary,
            info: data.createInfo,
            label: data.createLabel,
            reportedBy: user.sub,
            assignedTo: data.createAssignedTo,
        });
        return fetch(updateurl + issueid.toString(), {
            method: "PUT",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    updateIssueStatus(update: string, id: number, updateid: number) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        const completeURL = `${updateissuestatusurl}?&update=${update}&id=${id}&updateid=${updateid}`;
        return fetch(completeURL, {
            method: "PUT",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    deleteIssue(id: number) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const seprator = "/";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        return fetch(url + seprator + id.toString(), {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
        });
    },
};

export { IssueAPI };
