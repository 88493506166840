/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TransactonState {
    refreshKey: number;
}

const initialState: TransactonState = {
    refreshKey: 0,
};

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {
        refreshTransactions: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshTransactions } = transactionSlice.actions;

export default transactionSlice.reducer;
