import { UserAuth } from "./models/UserAuth";
import { FuelDTO } from "./models/Fuel";
import IUserInfoData from "./types/userinfo";
import { Constants } from "./constants";

// const baseUrl = "http://fleetweave.com:8180";
const url = Constants.URL_FUEL_SERVICE + Constants.URL_FUEL_ENDPOINT;
const urlFilteredFuelList =
    Constants.URL_FUEL_SERVICE + Constants.URL_FUEL_FILTERED_LIST;
const vehicleFuelurl =
    Constants.URL_FUEL_SERVICE + Constants.URL_FUELVAHICLE_ENDPOINT;

function parseJSON(response: Response) {
    console.log("parseJSON");
    return response.json();
}
function getBearerToken() {
    const data = sessionStorage.getItem("token");
    const userToken = JSON.parse(data || "");
    return userToken.access_token as string;
}
function getCurrentUser() {
    const data = sessionStorage.getItem("userinfo");
    const userinfodata = JSON.parse(data || "");
    return userinfodata as IUserInfoData;
}
const fuelAPI = {
    getFuelEntry() {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        console.log(token);
        return fetch(url, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    getFilteredFuelEntry(
        vehicleId: number,
        startDate: Date,
        endDate: Date,
        page: number
    ) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        console.log(token);

        const completeURL = `${urlFilteredFuelList}?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}&vehicleId=${vehicleId}&page=${page}`;

        return fetch(completeURL, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    getFuelEntrtForVehicles(vehicleId: string) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        console.log(token);
        return fetch(vehicleFuelurl + vehicleId, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },

    addFuelEntry(data: FuelDTO) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        const starttime = `${String(data.createEntryDate)} ${String(
            data.createEntryTime
        )}`;
        const user = getCurrentUser();
        const message = JSON.stringify({
            vehicleId: Number(data.createVehicleId),
            fuelType: Number(data.createFuelType),
            entryDateTime: Date.parse(starttime),
            reference: data.createRef,
            vendorId: Number(data.createVendorId),
            quantity: Number(data.createQuantity),
            price: Number(data.createPrice),
            odometer: Number(data.createOdometer),
            operatorId: user.sub,
        });
        return fetch(url, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    updateFuelEntry(data: FuelDTO, updateid: number) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        const starttime = `${String(data.createEntryDate)} ${String(
            data.createEntryTime
        )}`;
        const user = getCurrentUser();
        const message = JSON.stringify({
            vehicleId: Number(data.createVehicleId),
            fuelType: Number(data.createFuelType),
            entryDateTime: Date.parse(starttime),
            reference: data.createRef,
            vendorId: Number(data.createVendorId),
            quantity: Number(data.createQuantity),
            price: Number(data.createPrice),
            odometer: Number(data.createOdometer),
            operatorId: user.sub,
        });
        const seprator = "/";
        return fetch(url + seprator + ((updateid as unknown) as string), {
            method: "PUT",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },

    deleteFuelEntry(id: string) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const seprator = "/";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        return fetch(url + seprator + id, {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
        });
    },
};

export { fuelAPI };
