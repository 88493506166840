/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ReminderState {
    refreshKey: number;
}

const initialState: ReminderState = {
    refreshKey: 0,
};

const reminderSlice = createSlice({
    name: "reminder",
    initialState,
    reducers: {
        refreshReminders: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshReminders } = reminderSlice.actions;

export default reminderSlice.reducer;
