/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BookingEntryState {
    refreshKey: number;
}

const initialState: BookingEntryState = {
    refreshKey: 0,
};

const BookingEntrySlice = createSlice({
    name: "bookingentry",
    initialState,
    reducers: {
        refreshBookingEntry: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshBookingEntry } = BookingEntrySlice.actions;

export default BookingEntrySlice.reducer;
