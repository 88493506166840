import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
    calendarEvents,
    birthdayEvents,
    holidayEvents,
    discoveredEvents,
    meetupEvents,
    otherEvents,
} from "@doar/shared/data/events";
import { IEvent } from "@doar/shared/types";
import IAssignmentData from "../../services/types/assignment";

// Define a type for the slice state

export interface EvenState {
    eventSources: IEvent[];
}

// // Define the initial state using that type
const initialState: EvenState = {
    eventSources: [],
};

interface IEventState {
    id: string;
    title: string;
    start: string;
    end: string;
    category: string;
    description?: string;
    assignmentStatus: number | null;
    priority: number | null;
    operatorId: string;
    vehicleId: number;
    startLongitude?: string | "";
    startLatitude?: string | "";
    startAddress?: string | "";
    endLongitude?: string | "";
    endLatitude?: string | "";
    endAddress?: string | "";
    distance?: string | "";
}

const eventSlice = createSlice({
    name: "events",
    initialState,
    reducers: {
        createEvent: {
            reducer: (state, action: PayloadAction<IEventState>) => {
                console.log("createEvent.reducer");
                const {
                    payload: { category, ...data },
                } = action;
                const source = state.eventSources.find(
                    (src) => src.category === category
                );

                console.log("createEvent.reducer.data");
                console.log(data);
                source?.events.push(data);
            },
            prepare: (
                id: string,
                title: string,
                startDate: string,
                endDate: string,
                category: string,
                assignmentStatus: number | null,
                priority: number | null,
                operatorId: string,
                vehicleId: number,
                startLongitude?: string | "",
                startLatitude?: string | "",
                startAddress?: string | "",
                endLongitude?: string | "",
                endLatitude?: string | "",
                endAddress?: string | "",
                distance?: string | "",
                startTime?: string,
                endTime?: string,
                description?: string
            ) => {
                console.log("createEvent.prepare");
                const start = startTime
                    ? `${startDate}T${startTime}:00`
                    : startDate;
                const end = endTime ? `${endDate}T${endTime}:00` : endDate;
                const data = {
                    id,
                    title,
                    start,
                    end,
                    description,
                    category,
                    assignmentStatus,
                    priority,
                    operatorId,
                    vehicleId,
                    startLongitude,
                    startLatitude,
                    startAddress,
                    endLongitude,
                    endLatitude,
                    endAddress,
                    distance,
                };
                console.log("createEvent.prepare.data");
                console.log(data);
                return { payload: { ...data } };
            },
        },
        addAllEvents: (state, action) => {
            console.log("createEvent.reducer start");
            console.log(action.payload);
            const result = (action.payload as Array<IAssignmentData>).map(
                (element) => {
                    return {
                        id: element.id.toString(),
                        start: moment(element.startDate).format(
                            "YYYY-MM-DD hh:mm:ss"
                        ),
                        end: moment(element.endDate).format(
                            "YYYY-MM-DD hh:mm:ss"
                        ),
                        title: element.title,
                        description: element.description,
                        assignmentStatus: element.assignmentStatus,
                        priority: element.priority,
                        operatorId: element.operatorId,
                        vehicleId: element.vehicleId,
                        startLatitude: element.startLatitude,
                        startLongitude: element.startLongitude,
                        startAddress: element.startAddress,
                        endLatitude: element.endLatitude,
                        endLongitude: element.endLongitude,
                        endAddress: element.endAddress,
                        distance: element.distance,
                    };
                }
            );
            const ImmediateAssignments: IEvent = {
                category: "1",
                backgroundColor: "#fcbfdc",
                borderColor: "#f10075",
                events: result.filter((obj) => {
                    return obj.priority?.toString() === "1";
                }),
            };
            const HighAssignments: IEvent = {
                category: "2",
                backgroundColor: "#ffdec4",
                borderColor: "#fd7e14",
                events: result.filter((obj) => {
                    return obj.priority?.toString() === "2";
                }),
            };
            const MediumAssignments: IEvent = {
                category: "3",
                backgroundColor: "#d9e8ff",
                borderColor: "#0168fa",
                events: result.filter((obj) => {
                    return obj.priority?.toString() === "3";
                }),
            };
            const LowAssignments: IEvent = {
                category: "4",
                backgroundColor: "#bff2f2",
                borderColor: "#00cccc",
                events: result.filter((obj) => {
                    return obj.priority?.toString() === "4";
                }),
            };

            const assignmentEvents: IEvent = {
                category: "1",
                backgroundColor: "#bff2f2",
                borderColor: "#00cccc",
                events: result,
            };
            console.log("createEvent.reducer result");
            console.log(result);

            console.log("createEvent.reducer assignmentEvents");
            console.log(assignmentEvents);
            // state.eventSources = [];
            state.eventSources = [...[ImmediateAssignments]];
            state.eventSources.push(HighAssignments);
            state.eventSources.push(MediumAssignments);
            state.eventSources.push(LowAssignments);
            console.log("createEvent.reducer state");
            console.log(state);
            console.log(state.eventSources);
            console.log("createEvent.reducer end");
        },
        editEvent: {
            reducer: (state, action: PayloadAction<IEventState>) => {
                const {
                    payload: { category, ...data },
                } = action;
                const source = state.eventSources.find(
                    (src) => src.category === category
                );
                if (source) {
                    const index = source.events.findIndex(
                        (el) => el.id === data.id
                    );
                    if (index !== undefined && index >= 0) {
                        source.events[index] = data;
                    }
                }
            },
            prepare: (
                id,
                title: string,
                startDate: string,
                endDate: string,
                category: string,
                assignmentStatus: number | null,
                priority: number | null,
                operatorId: string,
                vehicleId: number,
                startLongitude?: string | "",
                startLatitude?: string | "",
                startAddress?: string | "",
                endLongitude?: string | "",
                endLatitude?: string | "",
                endAddress?: string | "",
                distance?: string | "",
                startTime?: string,
                endTime?: string,
                description?: string
            ) => {
                const start = startTime
                    ? `${startDate}T${startTime}:00`
                    : startDate;
                const end = endTime ? `${endDate}T${endTime}:00` : endDate;
                const data = {
                    category,
                    id,
                    title,
                    start,
                    end,
                    description,
                    assignmentStatus,
                    priority,
                    operatorId,
                    vehicleId,
                    startLongitude,
                    startLatitude,
                    startAddress,
                    endLongitude,
                    endLatitude,
                    endAddress,
                    distance,
                };
                return { payload: { ...data } };
            },
        },
        deleteEvent: (
            state,
            action: PayloadAction<{ id: string; category: string }>
        ) => {
            const {
                payload: { id, category },
            } = action;
            const source = state.eventSources.find(
                (src) => src.category === category
            );
            if (source) {
                const index = source.events.findIndex((el) => el.id === id);
                if (index !== undefined && index >= 0) {
                    source.events.splice(index, 1);
                }
            }
        },
    },
});

export const {
    createEvent,
    editEvent,
    deleteEvent,
    addAllEvents,
} = eventSlice.actions;

export default eventSlice.reducer;
