/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable@typescript-eslint/no-unsafe-return */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IAssignmentData from "../../services/types/assignment";
import { assignmentAPI } from "../../services/assignment.services";

export interface VehicleAssignmentState {
    assignmentSources: IAssignmentData[];
    selecteVehicle: string;
    status: string;
    error: string | undefined;
}

const initialState: VehicleAssignmentState = {
    assignmentSources: [],
    selecteVehicle: "0",
    status: "idle",
    error: "",
};

export const fetchAssignments = createAsyncThunk(
    "vehicle/fetchAssignments",
    async (vehicleId: string) => {
        console.log("fetchPosts ###");

        const response = await assignmentAPI
            .getVehicleAssignmentList(vehicleId)
            .then((result) => {
                return result.json();
            })
            .then((myJson) => {
                console.log(" myjson ###");
                console.log(myJson);
                if ("errorstatus" in myJson) {
                    if (myJson.errorstatus === 401) {
                        console.log("Unauthorized");
                        // setError("Please check username and password");
                    }
                } else {
                    console.log(myJson);
                }
                return myJson as IAssignmentData[];
            });
        return response;
    }
);

export const fetchVehicleLocations = createAsyncThunk(
    "vehicle/fetchAssignments",
    async (vehicleId: string) => {
        console.log("fetchPosts ###");

        const response = await assignmentAPI
            .getVehicleAssignmentList(vehicleId)
            .then((result) => {
                return result.json();
            })
            .then((myJson) => {
                console.log(" myjson ###");
                console.log(myJson);
                if ("errorstatus" in myJson) {
                    if (myJson.errorstatus === 401) {
                        console.log("Unauthorized");
                        // setError("Please check username and password");
                    }
                } else {
                    console.log(myJson);
                }
                return myJson as IAssignmentData[];
            });
        return response;
    }
);

const vehicleDetailsSlice = createSlice({
    name: "vehicleAssignmentList",
    initialState,
    reducers: {
        selectVehicle(
            state,
            action: PayloadAction<{
                vehicleId: string;
            }>
        ) {
            const {
                payload: { vehicleId },
            } = action;

            console.log("vehicleDetailsSlice.reducer start");
            console.log("vehicleDetailsSlice.reducer vehicleId");
            console.log(vehicleId);
            state.selecteVehicle = vehicleId;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchAssignments.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(fetchAssignments.fulfilled, (state, action) => {
            state.status = "succeeded";
            console.log(action.payload);
            state.assignmentSources = action.payload;
            console.log(state);
        });
        builder.addCase(fetchAssignments.rejected, (state, action) => {
            console.log("action.payload ***##");
            state.status = "failed";
            state.error = action.error.message;
        });
    },
});

export const { selectVehicle } = vehicleDetailsSlice.actions;
export default vehicleDetailsSlice.reducer;
