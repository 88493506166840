/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable@typescript-eslint/no-unsafe-return */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IFuelData from "../../services/types/fuel";
import { fuelAPI } from "../../services/fuel.services";

export interface VehicleAssignmentState {
    fuelSources: IFuelData[];
    vehicleFuelSources: IFuelData[];
    selecteVehicle: string;
    selecteFuelEntry: IFuelData;
    status: string;
    error: string | undefined;
}

const initialState: VehicleAssignmentState = {
    fuelSources: [],
    vehicleFuelSources: [],
    selecteVehicle: "0",
    selecteFuelEntry: {
        vehicleId: 0,
        entryDateTime: 0,
        fuelType: 1,
        reference: "",
        vendorId: 1,
        quantity: 0,
        price: 0,
        odometer: 0,
        id: 0,
        createdOn: "",
        operatorId: "",
        operator: {
            id: 0,
            name: "",
            userId: "",
            email: "",
            dob: "",
            address: "",
            username: "",
            firstname: "",
            lastname: "",
        },
    },
    status: "idle",
    error: "",
};

export const fetchVehicleEntries = createAsyncThunk(
    "vehicle/fetchVehicleEntries",
    async (vehicleId: string) => {
        console.log("fetchPosts ###");

        const response = await fuelAPI
            .getFuelEntrtForVehicles(vehicleId)
            .then((result) => {
                return result.json();
            })
            .then((myJson) => {
                console.log(" myjson ###");
                console.log(myJson);
                if ("errorstatus" in myJson) {
                    if (myJson.errorstatus === 401) {
                        console.log("Unauthorized");
                        // setError("Please check username and password");
                    }
                } else {
                    console.log(myJson);
                }
                return myJson as IFuelData[];
            });
        return response;
    }
);

const fuelEntrySlice = createSlice({
    name: "fuelEntryList",
    initialState,
    reducers: {
        selectVehicle(
            state,
            action: PayloadAction<{
                vehicleId: string;
            }>
        ) {
            const {
                payload: { vehicleId },
            } = action;

            console.log("fuelEntrySlice.reducer start");
            console.log(vehicleId);
            state.selecteVehicle = vehicleId;
        },
        selectFuelEntry(
            state,
            action: PayloadAction<{
                fuelData: IFuelData;
            }>
        ) {
            const {
                payload: { fuelData },
            } = action;

            console.log("fuelEntrySlice.reducer start");
            console.log(fuelData);
            state.selecteFuelEntry = fuelData;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchVehicleEntries.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(fetchVehicleEntries.fulfilled, (state, action) => {
            state.status = "succeeded";
            console.log(action.payload);
            state.vehicleFuelSources = action.payload;
            console.log(state);
        });
        builder.addCase(fetchVehicleEntries.rejected, (state, action) => {
            console.log("action.payload ***##");
            state.status = "failed";
            state.error = action.error.message;
        });
    },
});

export const { selectVehicle, selectFuelEntry } = fuelEntrySlice.actions;
export default fuelEntrySlice.reducer;
