/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IssuesEntryState {
    refreshKey: number;
}

const initialState: IssuesEntryState = {
    refreshKey: 0,
};

const issuesEntrySlice = createSlice({
    name: "issuesentry",
    initialState,
    reducers: {
        refreshIssuesEntry: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshIssuesEntry } = issuesEntrySlice.actions;

export default issuesEntrySlice.reducer;
