/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FuelEntryNewState {
    refreshKey: number;
}

const initialState: FuelEntryNewState = {
    refreshKey: 0,
};

const fuelEntrySliceNew = createSlice({
    name: "fuelentrynew",
    initialState,
    reducers: {
        refreshFuelEntryNew: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshFuelEntryNew } = fuelEntrySliceNew.actions;

export default fuelEntrySliceNew.reducer;
