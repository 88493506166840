/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
    refreshKey: number;
}

const initialState: UserState = {
    refreshKey: 0,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        refreshUser: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshUser } = userSlice.actions;

export default userSlice.reducer;
