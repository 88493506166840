/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable@typescript-eslint/no-unsafe-return */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IIssueImagesData from "../../services/types/issueimages";
import { IssueAPI } from "../../services/issue.services";

export interface IssueImageState {
    imageSources: IIssueImagesData[];
    selectedIssue: string;
    status: string;
    error: string | undefined;
}

const initialState: IssueImageState = {
    imageSources: [],
    selectedIssue: "0",
    status: "idle",
    error: "",
};

export const fetchImages = createAsyncThunk(
    "issue/fetchImages",
    async (issueId: string) => {
        console.log("fetchPosts ###");

        const response = await IssueAPI.getImagesist(issueId)
            .then((result) => {
                return result.json();
            })
            .then((myJson) => {
                console.log(" myjson  fetchImages ###");
                console.log(myJson);
                if ("errorstatus" in myJson) {
                    if (myJson.errorstatus === 401) {
                        console.log("Unauthorized");
                        // setError("Please check username and password");
                    }
                } else {
                    console.log(myJson);
                }
                return myJson as IIssueImagesData[];
            });
        return response;
    }
);

const issueImageSlice = createSlice({
    name: "issueMessageList",
    initialState,
    reducers: {
        selectIssueForImage(
            state,
            action: PayloadAction<{
                issueId: string;
            }>
        ) {
            const {
                payload: { issueId },
            } = action;
            console.log(issueId);
            state.selectedIssue = issueId;
        },
        addIssueImage(state, action) {
            console.log(action.payload);
            const result = action.payload as IIssueImagesData;
            if (result) state.imageSources.push(result);
            else {
                console.log("cant push null result");
            }
            console.log(state.imageSources);
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchImages.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(fetchImages.fulfilled, (state, action) => {
            state.status = "succeeded";
            console.log(action.payload);
            state.imageSources = action.payload;
            console.log(state);
        });
        builder.addCase(fetchImages.rejected, (state, action) => {
            console.log("action.payload ***##");
            state.status = "failed";
            state.error = action.error.message;
        });
    },
});

export const { selectIssueForImage, addIssueImage } = issueImageSlice.actions;
export default issueImageSlice.reducer;
