import { configureStore, combineReducers } from "@reduxjs/toolkit";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import eventReducer from "./slices/event";
import uiReducer from "./slices/ui";
import chatUISlice from "./slices/chat-ui";
import issueSlice from "./slices/issues";
import vehicleDetailsSlice from "./slices/vehicle-details";
import issueMessageSlice from "./slices/issue-messages";
import issueImageSlice from "./slices/issue-images";
import fuelEntrySlice from "./slices/fuel-entry";
import reminderSlice from "./slices/reminder";
import inventorySlice from "./slices/inventory";
import transactionSlice from "./slices/transaction";
import storageSpaceSlice from "./slices/storagespace";
import vehicleTypeSlice from "./slices/vehicletype";
import vehicleSlice from "./slices/vehicle";
import fuelEntryNewSlice from "./slices/fuelentry";
import userSlice from "./slices/user";
import issueEntrySlice from "./slices/issues-entry";
import bookingEntrySlice from "./slices/booking-entry";

const persistConfig = {
    key: "doar",
    version: 1,
    storage,
};

export const rootReducer = combineReducers({
    events: eventReducer,
    ui: uiReducer,
    chatUI: chatUISlice,
    issuesList: issueSlice,
    vehicleAssignmentList: vehicleDetailsSlice,
    issueMessageList: issueMessageSlice,
    issueImagesList: issueImageSlice,
    fuelEntryLists: fuelEntrySlice,
    reminderLists: reminderSlice,
    inventoryLists: inventorySlice,
    transactionLists: transactionSlice,
    storageSpaceLists: storageSpaceSlice,
    vehicleTypeLists: vehicleTypeSlice,
    vehicleLists: vehicleSlice,
    fuelEntryNewList: fuelEntryNewSlice,
    userList: userSlice,
    issueEntryLists: issueEntrySlice,
    bookingEntryLists: bookingEntrySlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//     reducer: rootReducer,
// });
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
