/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IIssueData, { IssueData } from "../../services/types/issue";

import { IssueAPI } from "../../services/issue.services";
import { IssueFilterDTO } from "../../services/models/IssueFilterDTO";
import { IIssueFilter } from "../../services/reduxtypes/IIssueFilter";

export interface IssueState {
    issueSources: IIssueData[];
    issueFilter: IIssueFilter;
    issueSelected: string;
    // prioritySelected: number;
    // statusSelected: number;
    // pageIndex: number;
    selectedIssue: IIssueData;
    status: string;
    error: string | undefined;
}
// // Define the initial state using that type
const initialState: IssueState = {
    issueSources: [],
    issueFilter: {
        sortBySelected: 0,
        prioritySelected: 0,
        statusSelected: 0,
        pageIndex: 0,
        pageSize: 10,
    },
    issueSelected: "",
    // prioritySelected: 0,
    // statusSelected: 0,
    // pageIndex: 0,
    status: "idle",
    error: "",
    selectedIssue: new IssueData(),
};

export const fetchIssuesFiltered = createAsyncThunk(
    "issues/fetchfilteredissues",
    async (filter: IssueFilterDTO) => {
        const response = await IssueAPI.getFilteredIssueList(filter)
            .then((result) => {
                return result.json();
            })
            .then((myJson) => {
                if ("errorstatus" in myJson) {
                    if (myJson.errorstatus === 401) {
                        console.log("Unauthorized");
                        // setError("Please check username and password");
                    }
                } else {
                    console.log(myJson);
                }
                return myJson as IIssueData[];
            });
        return response;
    }
);
const issueSlice = createSlice({
    name: "issuelist",
    initialState,
    reducers: {
        selectIssue(
            state,
            action: PayloadAction<{
                issueId: string;
            }>
        ) {
            const {
                payload: { issueId },
            } = action;
            console.log("issueSlice.selectIssue issueId");
            console.log(issueId);
            state.issueSelected = issueId;

            const firstIssue = state.issueSources.find((obj) => {
                return obj.id === Number(issueId);
            });
            state.selectedIssue = firstIssue as IIssueData;
            console.log("issueSlice.selectIssue selectedIssue");
            console.log(state.selectedIssue);
        },
        selectIssuePriority(
            state,
            action: PayloadAction<{
                priorityId: number;
            }>
        ) {
            const {
                payload: { priorityId },
            } = action;
            state.issueFilter.pageIndex = 0;
            state.issueFilter.prioritySelected = priorityId;
        },
        selectPageIndex(
            state,
            action: PayloadAction<{
                pageIndex: number;
            }>
        ) {
            const {
                payload: { pageIndex },
            } = action;
            console.log("pageIndex updating ***1234");
            console.log(state.issueFilter.pageIndex);
            console.log("to");
            console.log(pageIndex);
            state.issueFilter.pageIndex = pageIndex;
            console.log("pageIndex updated to");
            console.log(state.issueFilter.pageIndex);
        },
        selectIssueStatus(
            state,
            action: PayloadAction<{
                statusId: number;
            }>
        ) {
            const {
                payload: { statusId },
            } = action;
            state.issueFilter.pageIndex = 0;
            state.issueFilter.statusSelected = statusId;
        },
        addIssue(state, action) {
            console.log(action.payload);
            const result = action.payload as IIssueData;
            if (result) state.issueSources.push(result);
            else {
                console.log("cant push null result");
            }
            console.log("state.issueSources after push");
            console.log(state.issueSources);
        },
        addAllIssues: (state, action) => {
            console.log("addAllIssues.reducer start");
            console.log(action.payload);
            const result = action.payload as IIssueData[];

            console.log("addAllIssues.reducer result");
            console.log(result);

            // console.log("addAllIssues.reducer assignmentEvents");
            // console.log(assignmentIssues);
            // // state.eventSources = [];
            state.issueSources = result;
            console.log("addAllIssues.reducer state");
            console.log(state);
            console.log(state.issueSources);
            console.log("addAllIssues.reducer end");
        },
        appendIssues: (state, action) => {
            console.log("appendIssues.reducer start");
            console.log(action.payload);
            const result = action.payload as IIssueData[];

            console.log("appendIssues.reducer result");
            console.log(result);

            // console.log("addAllIssues.reducer assignmentEvents");
            // console.log(assignmentIssues);
            // // state.eventSources = [];
            //             let simpleArray = [1, 4, 5, 1, 1, 1];
            // simpleArray = simpleArray.filter(
            //   (element, i) => i === simpleArray.indexOf(element)
            // );

            // for (const issuesingle of result) {
            //     if (!state.issueSources.includes(issuesingle)) {
            //         state.issueSources.push(issuesingle);
            //     }
            // }
            // console.log("appendIssues.duplicate myset creation *****");
            // const myset = [...new Set(state.issueSources)];
            // console.log("appendIssues.duplicate myset *****");
            // console.log(myset);
            // myset.push(...result);
            // console.log("appendIssues.duplicate myset after push *****");
            // console.log(myset);

            // state.issueSources = myset;
            state.issueSources.push(...result);
            console.log("appendIssues.reducer state &&&");
            console.log(state);
            console.log(state.issueSources);
            console.log("appendIssues.reducer end");
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchIssuesFiltered.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(fetchIssuesFiltered.fulfilled, (state, action) => {
            state.status = "succeeded";
            console.log(action.payload);
            state.issueSources = action.payload;
            console.log(state);
        });
        builder.addCase(fetchIssuesFiltered.rejected, (state, action) => {
            console.log("action.payload ***##");
            state.status = "failed";
            state.error = action.error.message;
        });
    },
});

export const {
    addIssue,
    addAllIssues,
    selectIssuePriority,
    selectIssueStatus,
    selectIssue,
    selectPageIndex,
    appendIssues,
} = issueSlice.actions;
export default issueSlice.reducer;
