/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable@typescript-eslint/no-unsafe-return */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import IIssueMessageData from "../../services/types/issuemessages";
import { IssueAPI } from "../../services/issue.services";

export interface IssueMessageState {
    messageSources: IIssueMessageData[];
    selectedIssue: string;
    status: string;
    error: string | undefined;
}

const initialState: IssueMessageState = {
    messageSources: [],
    selectedIssue: "0",
    status: "idle",
    error: "",
};

export const fetchMessages = createAsyncThunk(
    "vehicle/fetchAssignments",
    async (issueId: string) => {
        console.log("fetchPosts ###");

        const response = await IssueAPI.getMessageList(issueId)
            .then((result) => {
                return result.json();
            })
            .then((myJson) => {
                console.log(" myjson  fetchMessages ###");
                console.log(myJson);
                if ("errorstatus" in myJson) {
                    if (myJson.errorstatus === 401) {
                        console.log("Unauthorized");
                        // setError("Please check username and password");
                    }
                } else {
                    console.log(myJson);
                }
                return myJson as IIssueMessageData[];
            });
        return response;
    }
);

const issueMessageSlice = createSlice({
    name: "issueMessageList",
    initialState,
    reducers: {
        selectIssueForMessage(
            state,
            action: PayloadAction<{
                issueId: string;
            }>
        ) {
            const {
                payload: { issueId },
            } = action;

            console.log("issueMessageSlice.reducer start");
            console.log("issueMessageSlice.reducer issueId");
            console.log(issueId);
            state.selectedIssue = issueId;
        },
        addIssueMessage(state, action) {
            console.log("addAllIssues.reducer start");
            console.log("addAllIssues.reducer payload");
            console.log(action.payload);
            const result = action.payload as IIssueMessageData;
            console.log("addAllIssues.reducer result");
            console.log(result);
            console.log("state.issueSources");
            console.log(state.messageSources);
            console.log("issue");
            if (result) state.messageSources.push(result);
            else {
                console.log("cant push null result");
            }
            console.log("state.issueSources after push");
            console.log(state.messageSources);
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchMessages.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(fetchMessages.fulfilled, (state, action) => {
            state.status = "succeeded";
            console.log(action.payload);
            state.messageSources = action.payload;
            console.log(state);
        });
        builder.addCase(fetchMessages.rejected, (state, action) => {
            console.log("action.payload ***##");
            state.status = "failed";
            state.error = action.error.message;
        });
    },
});

export const {
    selectIssueForMessage,
    addIssueMessage,
} = issueMessageSlice.actions;
export default issueMessageSlice.reducer;
