import { AssignmentDTO } from "./models/Assignment";
import { Constants } from "./constants";
import { UserBookingDTO } from "./models/UserBooking";

const url =
    Constants.URL_ASSIGNMENT_SERVICE + Constants.URL_ASSIGNMNET_ENDPOINT;
const cityurl =
    Constants.URL_ASSIGNMENT_SERVICE + Constants.URL_ASSIGNMNET_CITY_ENDPOINT;
const vehicleurl =
    Constants.URL_ASSIGNMENT_SERVICE + Constants.URL_ASSIGNMENTVEHICLE_ENDPOINT;
const assignmentstatusurl =
    Constants.URL_ASSIGNMENT_SERVICE + Constants.URL_ASSIGNMENTSTATUS_ENDPOINT;
const updateassiggneeurl =
    Constants.URL_ASSIGNMENT_SERVICE + Constants.URL_UPDATE_ASSIGNEE_ENDPOINT;
const addBookingUrl =
    Constants.URL_ASSIGNMENT_SERVICE + Constants.URL_ASSIGNMNET_ENDPOINT;
const bookingStatusUpdateUrl =
    Constants.URL_ASSIGNMENT_SERVICE +
    Constants.URL_ASSIGNMNET_RENTAL_STAUS_UPDATE_ENDPOINT;
const calculateFareUrl =
    Constants.URL_ASSIGNMENT_SERVICE + Constants.URL_CALCULATE_FARE_ENDPOINT;
function parseJSON(response: Response) {
    console.log("parseJSON");
    return response.json();
}

function getBearerToken() {
    const data = sessionStorage.getItem("token");
    const userToken = JSON.parse(data || "");
    return userToken.access_token as string;
}

const assignmentAPI = {
    getAssignmentList() {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        return fetch(url, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },
    getVehicleAssignmentList(vehicleid: string) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        return fetch(vehicleurl + vehicleid, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },

    addAssignment(data: AssignmentDTO) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");

        const starttime = `${String(data.createStartDate)} ${String(
            data.createStartTime
        )}`;
        const endtime = `${String(data.createEndDate)} ${String(
            data.createEndTime
        )}`;
        console.log("addAssignment service");
        console.log(starttime);
        console.log(endtime);
        const message = JSON.stringify({
            title: data.createAddTitle,
            operatorId: data.createAddOperator,
            description: data.createDescription,
            vehicleId: data.createAddVehicle,
            startDate: Date.parse(starttime),
            endDate: Date.parse(endtime),
            assignmentStatus: data.createAddIssueStatus,
            priority: data.createAddIssuePriority,
            startLongitude: data.startLongitude,
            startLatitude: data.startLatitude,
            startAddress: data.startAddress,
            endLongitude: data.endLongitude,
            endLatitude: data.endLatitude,
            endAddress: data.endAddress,
        });
        console.log("message");
        console.log(message);
        return fetch(url, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    getAssignmentStatus(assignmentid: string) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        return fetch(assignmentstatusurl + assignmentid, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },

    updateAssignee(
        createAddAssetId: string,
        createAssignedTo: string | undefined,
        id: number
    ) {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");
        console.log("updateAssignee service");
        const message = JSON.stringify({
            operatorId: createAssignedTo,
            vehicleId: createAddAssetId,
        });
        console.log("message");
        console.log(message);
        const completeURL = `${updateassiggneeurl}/${id}`;
        return fetch(completeURL, {
            method: "PUT",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    getCityList() {
        const token = getBearerToken();
        const bearer = "Bearer ";
        const myHeaders = new Headers();
        myHeaders.append("Authorization", bearer + token);
        return fetch(cityurl, {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        });
    },

    addBooking(data: UserBookingDTO) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const starttime = `${String(data.createStartDate)} ${String(
            data.createStartTime
        )}`;
        const endtime = `${String(data.createEndDate)} ${String(
            data.createEndTime
        )}`;
        console.log("addBooking service");
        console.log(starttime);
        console.log(endtime);
        const message = JSON.stringify({
            rentalPStartDate: Date.parse(starttime),
            rentalPEndDate: Date.parse(endtime),
            rentalAStartDate: Date.parse(starttime),
            rentalAEndDate: Date.parse(endtime),
            startLongitude: data.startLongitude,
            startLatitude: data.startLatitude,
            startAddress: data.createAddStartCity,
            endLongitude: data.endLongitude,
            endLatitude: data.endLatitude,
            endAddress: data.createAddEndCity,
            title: "booking",
            description: "booking",
            rentalCarType: data.createAddCarType,
            startDate: Date.parse(starttime),
            endDate: Date.parse(endtime),
            pickupEmail: data.createAddEmail,
            pickupPhNo: data.createAddPhno,
            pickupAddress: data.pickupAddress,
            userFname: data.createAddFName,
            userLname: data.createAddLName,
        });
        console.log("message");
        console.log(message);
        return fetch(addBookingUrl, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    calculateFare(data: UserBookingDTO) {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const starttime = `${String(data.createStartDate)} ${String(
            data.createStartTime
        )}`;
        const endtime = `${String(data.createEndDate)} ${String(
            data.createEndTime
        )}`;
        console.log("addBooking service");
        console.log(starttime);
        console.log(endtime);
        const message = JSON.stringify({
            carType: data.createAddCarType,
            pickupDateTime: Date.parse(starttime),
            dropoffDateTime: Date.parse(endtime),
            driverAge: 40,
            addInsurance: true,
            additionalDrivers: 1,
            mileage: 150,
            gps: false,
            childSeat: false,
            wifi: false,
            fullTankRequired: false,
            paymentMethod: "credit card",
            earlyReturn: false,
            lateReturn: false,
            promotionalDiscount: false,
        });
        console.log("message");
        console.log(message);
        return fetch(calculateFareUrl, {
            method: "POST",
            headers: myHeaders,
            body: message,
            redirect: "follow",
        });
    },
    // updateBookingStatus(assignmentId: number, statusId: number) {
    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     const completeURL = `${bookingStatusUpdateUrl}?assignmentid=${assignmentId}&rentalstatus=${statusId}`;

    //     return fetch(completeURL, {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: bookingStatusUpdateUrl,
    //         redirect: "follow",
    //     });
    // },

    updateBookingStatus(assignmentId: number, statusId: number) {
        const myHeaders = new Headers();
        const token = getBearerToken();
        const bearer = "Bearer ";
        myHeaders.append("Authorization", bearer + token);
        myHeaders.append("Content-Type", "application/json");

        // Define the URL with the path variable
        const completeURL = `${bookingStatusUpdateUrl}/${assignmentId}`;

        // Create the request body
        const requestBody = JSON.stringify({
            rentalStatus: statusId,
        });

        return fetch(completeURL, {
            method: "PUT", // Change to PUT
            headers: myHeaders,
            body: requestBody, // Include the JSON body
            redirect: "follow",
        });
    },
};

export { assignmentAPI };
