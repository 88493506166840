/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StorageSpaceState {
    refreshKey: number;
}

const initialState: StorageSpaceState = {
    refreshKey: 0,
};

const storagSpaceSlice = createSlice({
    name: "storagespace",
    initialState,
    reducers: {
        refreshStorageSpace: (state) => {
            state.refreshKey += 1;
        },
    },
});

export const { refreshStorageSpace } = storagSpaceSlice.actions;

export default storagSpaceSlice.reducer;
