// export default interface IIssueData {
//     assetId: number;
//     issueStatus?: any;
//     issuePriority?: any;
//     entryDateTime: number;
//     dueDateTime: number;
//     summary: string;
//     info: string;
//     label: string;
//     reportedBy: string;
//     assignedTo: string;
//     createdOn: Date;
//     id: number;
// }

export default interface IIssueData {
    assetId: number;
    asset: Asset;
    issueStatus: number;
    issuePriority: number;
    entryDateTime?: number;
    dueDateTime: number;
    summary: string;
    info: string;
    label: string;
    reportedBy: string;
    reporter: Reporter;
    assignedTo: string;
    assignee: Assignee;
    createdOn: string;
    id: number;
}

export interface Asset {
    id: number;
    name: string;
    licenseplate: string;
    type: string;
    year: string;
    make: string;
    model: string;
    trim: string;
    vin: string;
}

export interface Reporter {
    id: number;
    name: string;
    userId: string;
    email: string;
    mobileno: string;
    dob: any;
    gender: string;
    address: any;
    username: string;
    firstname: string;
    lastname: string;
}

export interface Assignee {
    id: number;
    name: string;
    userId: string;
    email: string;
    mobileno: any;
    dob: any;
    gender: any;
    address: any;
    username: string;
    firstname: string;
    lastname: string;
}
export class IssueData implements IIssueData {
    assetId: number;

    asset: Asset;

    issueStatus: number;

    issuePriority: number;

    entryDateTime?: number;

    dueDateTime: number;

    summary: string;

    info: string;

    label: string;

    reportedBy: string;

    reporter: Reporter;

    assignedTo: string;

    assignee: Assignee;

    createdOn: string;

    id: number;

    constructor(
        assetId: number = 0,
        asset: Asset = {
            id: 0,
            name: "",
            licenseplate: "",
            type: "",
            year: "",
            make: "",
            model: "",
            trim: "",
            vin: "",
        },
        issueStatus: number = 0,
        issuePriority: number = 0,
        entryDateTime?: number,
        dueDateTime: number = Date.now(),
        summary: string = "",
        info: string = "",
        label: string = "",
        reportedBy: string = "",
        reporter: Reporter = {
            id: 0,
            name: "",
            userId: "",
            email: "",
            mobileno: "",
            dob: null,
            gender: "",
            address: null,
            username: "",
            firstname: "",
            lastname: "",
        },
        assignedTo: string = "",
        assignee: Assignee = {
            id: 0,
            name: "",
            userId: "",
            email: "",
            mobileno: null,
            dob: null,
            gender: null,
            address: null,
            username: "",
            firstname: "",
            lastname: "",
        },
        createdOn: string = new Date().toISOString(),
        id: number = 0
    ) {
        this.assetId = assetId;
        this.asset = asset;
        this.issueStatus = issueStatus;
        this.issuePriority = issuePriority;
        this.entryDateTime = entryDateTime;
        this.dueDateTime = dueDateTime;
        this.summary = summary;
        this.info = info;
        this.label = label;
        this.reportedBy = reportedBy;
        this.reporter = reporter;
        this.assignedTo = assignedTo;
        this.assignee = assignee;
        this.createdOn = createdOn;
        this.id = id;
    }
}
